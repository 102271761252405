.custom-input {
    height: 40px; /* Adjust the height as per your requirement */
}
.items_main_mea{
    height: 72vh;
    overflow-y: scroll;
    overflow-x: hidden;
}
.items_main_cus{
    height: 67vh;
    overflow-y: scroll;
    overflow-x: hidden;
}
.items_main_pattern{
  height: 77vh;
  overflow-y: scroll;
  overflow-x: hidden;
}

/* Remove increment and decrement arrows from number input */
input[type="number"] {
    -moz-appearance: textfield; /* Firefox */
  }
  
  /* Remove increment and decrement arrows from Chrome */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  
  /* Remove increment and decrement arrows from Edge */
  input[type="number"] {
    -ms-inner-spin-button: none;
  }