
@font-face {
    font-family: 'nasalization'; /* Lowercase font-family */
    src: url('../../../public/media/fonts/nasalization-rg.otf') format('truetype'); /* Lowercase URL and format */
  }
  

.login_div{
    /* margin-bottom: 200px; */
    background-color: white;
    height: 320px;
    width: 350px;
    border-radius: 10px;
    box-shadow: 0 5px 5px 10px rgba(165, 162, 162, 0.1);
    /* background-image: linear-gradient(-225deg, #7DE2FC 0%, #B9B6E5 100%); */
}
.btn_login{
    background: linear-gradient(to left,rgb(64, 45, 235),rgb(92, 185, 216));
    border: none;
    width: 220px;
    height: 35px;
    color: white;
    border-radius: 5px;
    margin-left: -20px;
}
.btn_login:hover{
    background-color: none;
    color: rgb(82, 107, 114);
    border: 2px solid rgb(106, 147, 209);
    
}
.form_container{
    margin-bottom: -600px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    align-content: center;
}
.Para_up{
    font-size: 40px;
    /* font-family: 'Nasalization', sans-serif; */
    font-weight: 100;
    margin-bottom: -15px;
}
.head_style{
    font-size: 90px;
    font-family: "nasalization";
    font-weight: bold;
}
.Para_down{
    font-size: 23px;
    /* font-family: 'Nasalization', sans-serif; */
    font-weight: 100;
    margin-top: -25px;
    opacity: 0.7;
}
.input_heading{
    /* color: black; */
    font-family: 'nasalization', sans-serif;
    /* font-weight: normal; */
    margin: 0 auto; 
   
}
.login_logo{
    position: absolute;
    top: 0;
    left: 85%;
}
.login_text{
    position: absolute;
    bottom: 17%;
    left: 0;
    right: 30%;
    fill: white;
}
.text_form_top{
    text-align: center;
}
.line {
    text-align: center;
    width: 20%; /* Or adjust the width as needed */
    border-bottom: 2.5px solid rgb(47, 136, 219); /* Example line styling */
    margin-bottom: 10px; /* Space between line and label */
    margin-left: 120px;
  }

