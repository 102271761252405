.fabric1,.fabric2,.fabric2,.fabric3,.fabric4,.fabric5{
    border: 1px solid black;
    height: 32px;
    width: 35px;
    margin-left: 10px;
}

.fabric_button{
    border: none;
    height: 25px;
    width: 35px;
    /* margin: 4px; */
    background-color: transparent;
    transition: 0.5s;
}
.fabric_button:hover{
    transform: scale(1.2);
}
.fabric1{
    background-image: url("../../../../public/media/fabrics/test_03.jpeg");
    background-size: cover;
}
.fabric2{
    background-image: url("../../../../public/media/fabrics/test01.jpg");
    background-size: cover;
}
.fabric3{
    background-image: url("../../../../public/media/fabrics/test_02.avif");
    background-size: cover;
}
.fabric4{
    background-image: url("../../../../public/media/fabrics/ukrainian-floral-flower-embroidery-seamless-pattern-vector-ethnic-illustration_658151-3314.avif");
    background-size: cover;
}