.landing{
  background-image: url("../public/media/login/test2.jpg");
  background-size:cover;
  background-repeat: no-repeat;
  height: 100%;
}
.home{
  background-image: url("../public/media/login/MicrosoftTeams-image.png");
  background-size:cover;
  background-repeat: no-repeat;
  height: 100%;
}

.leftside_items{
  height: 63vh;
  overflow-y: scroll;
  overflow-x: hidden;
}
.leftside_items_sleeve{
  height: 68.5vh;
  overflow-y: scroll;
  overflow-x: hidden;
}

.items_pattern{
  height: 80.5vh;
  overflow-y: scroll;
  overflow-x: hidden;
}

.items_display{
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  align-content: center;
  height: 180px !important;
  width: 125px !important;
  margin: 4px;
  padding: 5px;
  background-color: none;
  font-size: 15px;
}

.items_display_chudi{
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  align-content: center;
  height: 200px !important;
  width: 125px !important;
  margin: 4px;
  padding: 5px;
  background-color: none;
  font-size: 15px;

}
.icon_image{
  height: auto;
  width: 125px;
  margin-left: -3px;
 filter: drop-shadow(5px 10px 15px rgba(8, 9, 13, 0.4));
}
.icon_image_chudi{
  height: 100px;
  width: 105px;
  margin-left: -3px;
 filter: drop-shadow(5px 10px 15px rgba(8, 9, 13, 0.4));
}

.icon_image_kids{
  height: 120px;
  width: 125px;
  margin-left: -3px;
 filter: drop-shadow(5px 10px 15px rgba(8, 9, 13, 0.4));
}


.active_nav_tabs{
  /* background: linear-gradient(to right,pink,darkblue) !important; */
  color: #e35146 !important;
  /* background-color: rgba(107, 240, 229, 0.329) !important; */
  border-bottom: 3px solid #e35146 !important;
}
.custom-nav .nav-link {
  padding: 0.3rem 1rem; 
  width: 85px;/* Adjust padding to reduce width */
}
.nav_tabs .nav-link {
  padding: 0.1rem 1rem; 
  width: auto;/* Adjust padding to reduce width */
}

.select_style{
  width: 150px;
  background-color: rgba(209, 85, 102, 0.301) !important;
  color: white;
  border: none !important;
  padding: 5px;
  border-radius: 3px;
}
.select_style_last{
  background-color: rgba(209, 85, 102, 0.301) !important;
  color: white;
  border: none !important;
  padding: 5px;
}
.select_style:hover{
  background-color: rgba(209, 85, 102, 0.89);
}
.select_style_last:hover{
  background-color: rgba(209, 85, 102, 0.89);
}
.logout_style{
  border-radius: 50%;
  height: 25px;
  width: 25px;
}
